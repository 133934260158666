<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("adjustwarhouse") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentnumber")
            }}</label>
            <input
              v-model="form.transaction_number"
              disabled
              class="form-control mb-4"
            />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentdate")
            }}</label>
            <input
              v-model="form.updatedAt"
              disabled
              class="form-control mb-4"
            />
          </div>
        </div>

        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">{{
              $t("transfertowarehouse")
            }}</label>
            <!-- <select class="form-select" v-model="form.toWarehouseId" disabled>
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select> -->
            <input
              type="text"
              class="form-control mb-4"
              disabled
              v-model="form.toWarehouseId"
            />
          </div>
        </div>
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">{{
              $t("annotation")
            }}</label>
            <input v-model="form.remark" class="form-control mb-4" disabled />
          </div>
        </div>
        <!-- <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label"
              >{{ $t("status") }}
            </label>
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.status"
            >
              
              <option
                :disabled="
                  form.status == 'รับสินค้าแล้ว' || form.status == 'รอส่งของ'
                "
                value="รอจัดของ"
              >
                รอจัดของ
              </option>
              <option
                :disabled="form.status == 'รับสินค้าแล้ว'"
                value="รอส่งของ"
              >
                รอส่งของ
              </option>
              <option value="รับสินค้าแล้ว">รับสินค้าแล้ว</option>
            </select>
          </div>
        </div> -->

        <!-- <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="addItem()"
            >
              + เพิ่ม
            </button>
            <AddItem
              :dialogAddItem="dialogAddItem"
              :warehouseId="form.fromWarehouseId"
              @addProduct="addProduct"
              @closeDialogAddItem="closeDialogAddItem"
            />
          </div>
        </div> -->

        <div class="table-responsive" style="padding-top: 3px">
          <a-table :dataSource="dataItemsShow" :columns="columns">
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'no'">
                {{ $t("no") }}
              </template>
              <template v-if="column.dataIndex === 'stockAmt'">
                {{ $t("stockAmt") }}
              </template>
              <template v-if="column.dataIndex === 'stockAmtNew'">
                {{ $t("stockAmtNew") }}
              </template>
              <template v-if="column.dataIndex === 'unit'">
                {{ $t("unitname") }}
              </template>
              <template v-if="column.dataIndex === 'code'">
                {{ $t("productcode") }}
              </template>
              <template v-if="column.dataIndex === 'name'">
                {{ $t("productname") }}
              </template>
            </template>
            <template #bodyCell="{ column, index, record }">
              <template v-if="column.dataIndex === 'no'">
                {{ record.no }}
              </template>
              <template v-if="column.dataIndex === 'code'">
                {{ record.product.code }}
              </template>
              <template v-if="column.dataIndex === 'name'">
                {{ record.product.name }}
              </template>
              <template v-if="column.dataIndex === 'stockAmt'">
                {{ record.stockAmt }}
              </template>
              <template v-if="column.dataIndex === 'stockAmtNew'">
                {{ record.stockAmtNew }}
              </template>
              <template v-if="column.dataIndex === 'unit'">
                <select
                  class="form-select"
                  v-model="record.productUnitId"
                  disabled
                >
                  <option v-for="item in units" :key="item" :value="item.id">
                    {{ item.code }} | {{ item.name }}
                  </option>
                </select>
              </template>
            </template>
          </a-table>
        </div>

        <!-- <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >โอนเข้าคลัง</label
            >
            <input class="form-control mb-4" />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input disabled class="form-control" />
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <!-- <button
            type="submit"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submittransaction()"
          >
            {{ $t("save") }}
          </button> -->
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("back") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import transferApi from "@/api/transfer/";
import moment from "moment";

export default {
  components: {
    AddItem,
  },
  setup() {
    document.title = "J&N | สร้างใบโอนสินค้า";
  },
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    isValidRetailPrice: false,
    dialogAddItem: false,
    form: {
      transaction_number: "",
      fromWarehouseId: 0,
      toWarehouseId: 0,
      updatedAt: "",
      remark: "",
      status: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    warehouses: [],
    products: [],
    dataItemsShow: [],
    units: [],
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "code", dataIndex: "code" },
      { title: "name", dataIndex: "name" },
      { title: "stockAmt", dataIndex: "stockAmt", width: 150 },
      { title: "stockAmtNew", dataIndex: "stockAmtNew", width: 150 },
      { title: "unit", dataIndex: "unit", width: 200 },
    ],
  }),

  async created() {
    let id = this.$route.query.id;
    // await this.getAllWarehouse();

    await this.getTransaction(id);
    await this.getAllUnit();
  },
  methods: {
    async getAllUnit() {
      let companyId = parseInt(localStorage.getItem("companyId"));
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.unit.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.units = responseData.data;

        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    formatProductCode(productId) {
      if (productId) {
        const result = this.products.filter(
          (element) => productId == element.id
        );
        console.log("result product", productId);
        // console.log("result", result[0].code);
        return result[0].code;
      } else {
        return "-";
      }
    },
    formatProductName(productId) {
      if (productId) {
        const result = this.products.filter(
          (element) => productId == element.id
        );
        console.log("result product", result);
        // console.log("result", result[0].name);
        return result[0].name;
      } else {
        return "-";
      }
    },
    addQtyByIndex(index, stockAmt) {
      this.dataItemsShow[index] = {
        ...this.dataItemsShow[index],
        stockAmt: stockAmt,
      };
      // this.sumPayout();
    },
    goToEdit(id) {
      let routeData = this.$router.resolve({
        name: "WH4-edit",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    addProduct(item) {
      console.log("addProduct", item);
      this.dataItemsShow.push(item);
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
      }
    },
    async getAllProduct() {
      let responseData = [];
      try {
        responseData = await whApi.product.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.products = responseData.data;
      }
    },
    async getTransaction(id) {
      let responseData = [];
      try {
        responseData = await transferApi.transfer.getOneAdjustStockTransaction(
          id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        let result = responseData.data;
        console.log("result", result);
        this.form.id = result.id;
        this.form.transaction_number = result.transaction_number;
        this.form.fromWarehouseId = result.form_warehouse_id;
        this.form.toWarehouseId =
          result.warehouse.warehouse_code +
          " | " +
          result.warehouse.warehouse_short_name +
          " | " +
          result.warehouse.warehouse_full_name;
        this.form.remark = result.remark;
        this.form.stockAmt = result.stockAmt;
        this.form.stockAmtNew = result.stockAmtNew;
        this.form.status = result.status;
        this.form.updatedAt = moment(result.updatedAt).format(
          "DD/MM/YYYY - HH:mm:ss น."
        );
        this.dataItemsShow = result.adjustStockTransactonItems;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
      }
    },
    // async cutStock(item) {
    //   let getone = [];
    //   getone = await transferApi.transfer.getOne(item.id);
    //   console.log("getone", getone);
    //   // transaction_number
    //   // form_warehouse_id
    //   // productTransfers
    //   // to_warehouse_id

    //   let responseData = [];
    //   let body = {
    //     fromWarehouseId: getone.data.form_warehouse_id,
    //     toWarehouseId: getone.data.to_warehouse_id,
    //     products: getone.data.productTransfers,
    //   };

    //   try {
    //     responseData = await whApi.warehouse.updatestock(body);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //   }
    // },
    async cutStock() {
      await this.validateForm();
      this.isSubmit = true;
      let responseData = [];
      let body = {
        ...this.form,
        products: this.dataItemsShow,
      };

      try {
        responseData = await whApi.warehouse.updatestock(body);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // this.productstock = responseData.data[0];
        // await this.submittransaction();
      }
    },
    async submittransaction() {
      let responseData = [];
      let body = {
        transaction_number: this.form.transaction_number,
        form_warehouse_id: this.form.fromWarehouseId,
        to_warehouse_id: this.form.toWarehouseId,
        products: this.dataItemsShow,
        status: this.form.status,
        remark: this.form.remark,
      };
      console.log("body", body);
      try {
        responseData = await transferApi.transfer.update(this.form.id, body);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        await this.cutStock();
        // this.productstock = responseData.data[0];
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        }).then(() => {
          this.$router.go(-1);
        });
      }
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
  },
};
</script>
